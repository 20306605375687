/* This styling is specifically for react-slick library */
.button__bar {
  display: flex !important;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  margin: auto;
  padding: 0;
  border-radius: 40px;
  width: 70px;
  text-align: center;
  position: relative;
  top: -25px;
}

.button__bar li {
  list-style: none;
  cursor: pointer;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
}

.button__bar li button {
  border: none;
  background: #D3D3D3;
  color: transparent;
  cursor: pointer;
  display: block;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  padding: 4px;
}

.button__bar li.slick-active button {
  background-color: #AB7254;
  width: 50px;
  border-radius: 7.5px;
  padding: 4px;
}

.MuiPickersDay-daySelected {
  background-color: #D28B53 !important;
}

.MuiPickersDay-daySelected:hover {
  background-color: #D28B53 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #D28B53 !important;
}