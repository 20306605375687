@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap');
@import '~@sweetalert2/theme-material-ui/material-ui.scss';

* {
  border: 0;
  margin: 0;
  box-sizing: border-box;
  // overflow-x: clip,
}

/*Exception made to hide scroll from input[type=number]*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.special-label {
  display: block !important; /* Change from 'none' to 'block' to make it visible */
  position: absolute;
  z-index: 1;
  font-size: 13px;
  left: 46px;
  top: -8px;
  background: #fff;
  padding: 0 2px;
  white-space: nowrap;
}


